<template>
  <form-medication />
</template>

<script>
import formMedication from '../form/medication/Form-medication-medication.vue'

export default ({
  components: {
    formMedication,
  },
})
</script>
