<template>
  <div>
    <b-card
      no-body
      class="mb-2 pb-2 pl-2 pr-2"
    >
      <h4 class="mt-2 pt-2">
        {{ this.$store.getters['formMedication/action'] }}
      </h4>
      <div class="mt-2 d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb :items="items" />
      </div>
      <validation-observer ref="medicationRules">
        <b-form>
          <b-row>

            <b-col
              cols="6"
            >
              <b-form-group
                label="Medicamento"
                label-for="medication"
                class="mt-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="medicamento"
                  rules="required"
                >

                  <v-select
                    v-model="selected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="option"
                    label="name"
                    @search="findMedications"
                  >
                    <!-- eslint-disable-next-line vue/no-unused-vars  -->
                    <template #no-options="{ search, searching, loading }">
                      <template v-if="searching">
                        Não conseguimos encontrar nenhum medicamento para a sua busca.
                      </template>
                      <template v-if="search" />
                      <template v-if="loading" />
                    </template>
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>

      <template v-if="selected.length !== 0">

        <div class="mt-2">
          {{ selected.name }}
        </div>
        <div>
          <strong>Princípio ativo:</strong> {{ selected.active_ingredients }}
        </div>
        <!-- <div>
          <strong>Tipo:</strong> {{ selected.medication_format }}
        </div> -->
        <div>
          <strong>Laboratório:</strong> {{ selected.laboratory }}
        </div>
        <div>
          <strong>Dosagem:</strong> {{ selected.dosage }} {{ selected.dosage_type }}
        </div>

      </template>

      <b-col
        cols="12"
        class="mt-2"
      >
        <b-button
          type="button"
          variant="outline-primary"
          @click="cancel"
        >
          Cancelar
        </b-button>
        <b-button
          type="button"
          variant="primary"
          class="ml-2"
          @click="next"
        >
          Continuar
        </b-button>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BBreadcrumb, BForm, BRow, BCol, BFormGroup, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BBreadcrumb,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      link: '',
      selected: '',
      option: [],
      items: [
        {
          text: 'Medicamento',
          active: false,
        },
        {
          text: 'Horário',
          active: true,
        },
        {
          text: 'Frequência',
          active: true,
        },
        {
          text: 'Duração',
          active: true,
        },
        {
          text: 'Resumo',
          active: true,
        },
      ],
      showMessage: false,
    }
  },

  mounted() {
    this.selected = this.$store.getters['formMedication/medication']
    this.link = this.$store.getters['formMedication/link']
  },

  methods: {
    next() {
      this.$refs.medicationRules.validate().then(success => {
        if (success) {
          this.$store.commit('formMedication/UPDATE_MEDICATION', this.selected)
          this.$router.push(`${this.link}-time`)
        }
      })
    },
    findMedications(search, loading) {
      clearTimeout(this.timer)
      if (search.length >= 3) {
        this.startTime(search, loading)
        loading(true)
      } else {
        this.option = []
      }
    },
    startTime(search, loading) {
      this.timer = setTimeout(() => {
        this.find(search, loading)
      }, 500)
    },
    find(search, loading) {
      this.$store.dispatch('formMedication/findMedication', search)
        .then(response => {
          if (response.data.data.medications_info !== null) {
            this.option = response.data.data.medications_info
            loading(false)
          } else {
            this.option = []
            loading(false)
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    cancel() {
      if (this.link === 'patient') {
        this.$store.commit('patient/UPDATE_SHOW_TAB', true)
        this.$store.commit('patient/UPDATE_SHOW_REMINDER_ADD', false)
        this.$store.commit('patient/UPDATE_SHOW_REMINDER_FAVORITE', true)
        this.$router.push(`${this.link}-edit`)
      } else {
        this.$router.push(this.link)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
